import './libs/eu-cookies.js';

var responsiveBreakpoint = 992;
var menuBreakpoint = 1181;

$(document).ready(function(){

    // ------------------- sliders
    $('.slider').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        autoplay: true,
        // arrows: false,
        autoplaySpeed: 2000,
        prevArrow: '<span class="arrow arrow-right"><i class="icon-arrow_left"></i></span>',
        nextArrow: '<span class="arrow arrow-left"><i class="icon-arrow_right"></i></span>',
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    let $careerImageSlider = $('.career .slider-part .slides');
    $careerImageSlider.slick({
        arrow: true,
        nextArrow: '<span class="arrow arrow-right"><i class="icon-iconmonstr-arrow-small2"></i></span>',
        prevArrow: '<span class="arrow arrow-left"><i class="icon-iconmonstr-arrow-small"></i></span>',
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: true
    });

    $('.career .slider-part .text-0').show();
    $careerImageSlider.on('init reInit beforeChange', function (event, slick, currentSlide, nextSlide) {
        $('.career .slider-part .hidden-text').each(function () {
            $(this).fadeOut();
        });
        $('.career .slider-part .text-' + nextSlide).fadeIn();
    });

    // let $contactImageSlider = $('.contact .main-gallery .items');
    // $contactImageSlider.slick({
    //     arrow: true,
    //     nextArrow: '<span class="arrow arrow-right"><i class="icon-iconmonstr-arrow-small2"></i></span>',
    //     prevArrow: '<span class="arrow arrow-left"><i class="icon-iconmonstr-arrow-small"></i></span>',
    //     autoplay: true,
    //     autoplaySpeed: 2500,
    //     infinite: true
    // });
    //
    // $('.contact .main-gallery .slider-text.text-0').show();
    // $contactImageSlider.on('init reInit beforeChange', function (event, slick, currentSlide, nextSlide) {
    //     $('.contact .main-gallery .slider-text').each(function () {
    //         $(this).fadeOut();
    //     });
    //     $('.contact .main-gallery .slider-text.text-' + nextSlide).fadeIn();
    // });


    $('.comments-slider').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        prevArrow: '<span class="arrow arrow-right"><i class="icon-arrow_left"></i></span>',
        nextArrow: '<span class="arrow arrow-left"><i class="icon-arrow_right"></i></span>',
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    arrows: false
                }
            }
        ]
    });

    // ------------------- END sliders


    $('#main-gallery').Chocolat();
    $('footer .gallery').Chocolat();
    $('#work-with-us .certificate').Chocolat();


    if ($(window).innerWidth() >= menuBreakpoint) {
        $(window).scroll(function () {
            let $menu = $('.menu');
            if ($(window).scrollTop() > 68) {
                $menu.addClass('fixed');
                $('body').addClass('padding-top');
            } else {
                $menu.removeClass('fixed');
                $('body').removeClass('padding-top');
            }
        });
    }

    // smooth scrolling
    smoothScroll('.scroll-to-hash');

    if (window.page == 'homepage') {
        smoothScroll('.footer-scroll');

        let hash = window.location.hash;
        let scrollOffset;
        if ($(window).innerWidth() >= menuBreakpoint) {
            scrollOffset = 180;
        } else {
            scrollOffset = 10;
        }
        if (hash) {
            $('html, body').animate({
                scrollTop: ($('#work-with-us').offset().top - scrollOffset)
            }, 1000, 'swing');
        }

        $('.scroll-down').on('click', function (e) {
            let menuHeight;
            e.preventDefault();
            if ($(window).innerWidth() >= menuBreakpoint) {
                menuHeight = $('.menu').height() + 62;
            } else {
                menuHeight = 44;
            }

            $('html, body').animate({
                scrollTop: ($('#work-with-us').offset().top - menuHeight)
            }, 1000, 'swing');
        });

        let animated = false;
        let delay = 500;
        $(window).scroll(function() {
            if ($('.technologies').is( ':in-viewport' )) {
                if (!animated) {
                    $('.technology-button').each(function() {
                        $(this).delay(delay).animate({
                            opacity: 1
                        },500);
                        delay += 500;
                    });
                    animated = true;
                }
            }
        });

    }


    // ------------------- smooth scrolling when click on other position
    if (window.page == 'career') {
        let menuHeight;
        if ($(window).innerWidth() >= menuBreakpoint) {
            menuHeight = 180;
        } else {
            menuHeight = 10;
        }
        let hash = window.location.hash;
        //console.log(hash);
        if (hash) {
            $('html, body').animate({
                scrollTop: ($('#job-offers').offset().top - menuHeight)
            }, 1000, 'swing');
        }
    }

    // ------------------- load more in gallery
    if (window.page == 'gallery') {
        let $load = $("#gallery-load-more");
        $('.references-gallery .item').slice(0, 12).show();
        $load.on('click', function (e) {
            e.preventDefault();
            $('.references-gallery .item:hidden').slice(0, 12).fadeIn();
            if ($('.references-gallery .item:hidden').length == 0) {
                $load.css({'opacity': 0});
            }
        });
    }


    // ------------------- menu
    if ($(window).innerWidth() < menuBreakpoint) {
        let $toogleBtn = $('#toggle');
        let $menuResponsive = $('.responsive-menu');
        let $menuResponsiveItems = $('.menu-links a');

        $menuResponsiveItems.click(function () {
            $toogleBtn.removeClass('on');
            $menuResponsive.removeClass('open');
            $menuResponsive.removeAttr('aria-hidden');
            $('body').removeClass('noscroll');
        });

        $toogleBtn.click(function () {
            $toogleBtn.toggleClass('on');
            $menuResponsive.toggleClass('open');
            $menuResponsive.attr('aria-hidden', false);
            $('body').toggleClass('noscroll');
        });
    }
    // ------------------- END menu

    let $cv = $('#cv');
    $cv.on('change', function () {
        $('.application-form .fname').html(this.value.replace(/.*[\/\\]/, ''));
    });

    let $extraFile = $('#extraFile');
    $extraFile.on('change', function () {
        $('.contact-form .fname').html(this.value.replace(/.*[\/\\]/, ''));
    });

    // ------------------- show menu only on top scroll
    var didScroll;
    var lastScrollTop = 0;
    $(window).scroll(function(event){
        didScroll = true;
    });

    setInterval(function() {
        if (!$('body').hasClass('noscroll') && $(window).innerWidth() < menuBreakpoint) {
            if (didScroll) {
                hasScrolled();
                didScroll = false;
            }
        }
    }, 250);

    function hasScrolled() {
        var st = $(window).scrollTop();

        var delta = 5;

        if(Math.abs(lastScrollTop - st) <= delta) {
            return;
        }

        if (st > lastScrollTop && st > $('.responsive-menu').outerHeight()) {
            $('.responsive-menu').removeClass('nav-down').addClass('nav-up');
        } else {
            if(st + $(window).height() < $(document).height()) {
                $('.responsive-menu').removeClass('nav-up').addClass('nav-down');
            }
        }

        lastScrollTop = st;
    }
    // ------------------- END show menu only on top scroll


    // SUBSCRIBE FORM
    $('#subscribe-form').submit(function(e) {
        let $submitBtn = $(this).find('input[type="submit"]');
        $submitBtn.attr('disabled', true);
        e.preventDefault();
        let required = false;
        $('#subscribe-form:input[required]').each(function () {
            if ($(this).val() == '') {
                $submitBtn.attr('disabled', false);
                $(this).addClass('wrong');
            }
        });

        // TODO: check strange error: Failed to start the session because headers have already been sent by "" at line 0.
        let $message = $('#subscribe-form .message');
        $.ajax({
            url: '/subscribe',
            type: 'json',
            contentType: 'application/json',
            method: 'post',
            data: JSON.stringify({
                email: $(this).find('input[name="email"]').val(),
                locale: $(this).data('locale')
            }),
            error: function(response) {
                $submitBtn.attr('disabled', false);
                $message.fadeIn(200);
                $message.addClass('error');
                $message.html($.parseJSON(response.responseText).message);
            },
            success: function(data, textStatus, response) {
                ga('send', 'event', 'email', 'sent', 'newsletter');
                $message.fadeIn(200);
                $message.removeClass('error');
                $message.addClass('success');
                $message.html($.parseJSON(response.responseText).message);
            }
        });
    });

    // CONTACT FORM
    let $form = $('#contact-form');
    $('#contact-form-submit').on('click', function(e) {
        e.preventDefault();

        let $button = $(this);
        $button.attr('disabled', true);
        let locale = $form.data('locale');
        let allFilled = true;
        let $message = $('.form-errors');
        let $messageText = $('.form-errors .message .text');
        let $loader = $('.loader');
        let attachmentId = null;
        let correct = true;
        let correctFile = true;
        let fileMessage = 'Maximální velikost souboru je 2 MB.';


        $('.required').each(function () {
            if ($(this).val() == '') {
                $(this).addClass('wrong');
                allFilled = false;
            } else {
                $(this).removeClass('wrong');
            }
        });

        if (locale == 'en') {
            fileMessage = 'Maximum file size is 2 MB.';
        }

        if (document.getElementById('extraFile').files[0] !== undefined) {
            if (document.getElementById('extraFile').files[0].size > 2000000) {
                $('.fileLabel').addClass('wrong');
                $message.addClass('error');
                $message.fadeIn(500).delay(2000).fadeOut(500);
                $messageText.html(fileMessage);
                correctFile = false;
            }
        }

        if (!allFilled || !correctFile) {
            $button.attr('disabled', false);
            return;
        }

        let formData = new FormData();
        formData.append('file', document.getElementById('extraFile').files[0]);
        $loader.fadeIn();

        if (document.getElementById('extraFile').files[0] !== undefined) {
            $.when(
                $.ajax({
                    url: '/upload-attachment-file',
                    data: formData,
                    enctype: 'multipart/form-data',
                    processData: false,
                    contentType: false,
                    cache: 'false',
                    type: 'post',
                    success: function (response) {
                        $loader.fadeIn();
                        correct = true;
                        attachmentId = response;
                    },
                    error: function () {
                        $button.attr('disabled', false);
                        correct = false;
                        $message.addClass('error');
                        $message.fadeIn(500).delay(2000).fadeOut(500);
                        $messageText.html('Při nahrávání souboru došlo k chybě.');
                    }
                })
            ).done(function () {
                ajaxSaveForm($message, $form, $loader, $messageText, attachmentId, $button);
            });
        } else {
            ajaxSaveForm($message, $form, $loader, $messageText, null, $button);
        }
    });


    function ajaxSaveForm($message, $form, $loader, $messageText, attachmentId, $button) {
        $.ajax({
            url: '/save-contact-form',
            type: 'json',
            contentType: 'application/json',
            method: 'post',
            data: JSON.stringify({
                name: $form.find('input[name="name"]').val(),
                surname: $form.find('input[name="surname"]').val(),
                email: $form.find('input[name="email"]').val(),
                phone: $form.find('input[name="phone"]').val(),
                text: $form.find('textarea[name="text"]').val(),
                attachmentId: attachmentId,
                locale: $form.data('locale')
            }),
            error: function (response) {
                $button.attr('disabled', true);
                $message.addClass('error');
                $message.fadeIn(500).delay(2000).fadeOut(500);
                $messageText.html($.parseJSON(response.responseText).message);
                $loader.fadeOut(500);
            },
            success: function (data, textStatus, response) {
                ga('send', 'event', 'form', 'sent', 'kontakt');
                $('#contact-form-submit').hide();
                $message.fadeIn(500).delay(2000).fadeOut(500);
                $message.removeClass('error');
                $messageText.html($.parseJSON(response.responseText).message);
                $loader.fadeOut(500);
            }
        });
    }

    // JOB OFFER FORM
    let $jobForm = $('#job-offer-form');
    let $jobFormButton = $('#job-offer-form-submit');
    $jobFormButton.on('click', function(e) {

        $(this).attr('disabled', true);
        let locale = $jobForm.data('locale');
        let correct = false;
        let resumeID = null;
        let $message = $('.form-errors');
        let $messageText = $('.form-errors .message .text');
        let correctFile = true;
        let $loader = $('.loader');
        let fileMessage = 'Maximální velikost souboru je 2 MB.';

        e.preventDefault();
        let allFilled = true;
        $('.required').each(function () {
            if ($(this).val() == '') {
                if ($(this).hasClass('inputfile')) {
                    $('.fileLabel').addClass('wrong');
                } else {
                    $(this).addClass('wrong');
                }
                allFilled = false;
            } else {
                if ($(this).hasClass('inputfile')) {
                    $('.fileLabel').removeClass('wrong');
                } else {
                    $(this).removeClass('wrong');
                }
            }
        });

        if (locale == 'en') {
            fileMessage = 'Maximum file size is 2 MB.';
        }

        if (document.getElementById('cv').files[0] !== undefined) {
            if (document.getElementById('cv').files[0].size > 2000000) {
                $('.fileLabel').addClass('wrong');
                $message.addClass('error');
                $message.fadeIn(500).delay(2000).fadeOut(500);
                $messageText.html(fileMessage);
                correctFile = false;
            }
        } else {
            correctFile = false;
        }

        if (!allFilled || !correctFile) {
            $(this).attr('disabled', false);
            return;
        }

        let formData = new FormData();
        formData.append('file', document.getElementById('cv').files[0]);

        $.when(
            $.ajax({
                url: '/upload-resume-file',
                data: formData,
                enctype: 'multipart/form-data',
                processData: false,
                contentType: false,
                cache: 'false',
                type: 'post',
                success: function (response) {
                    $loader.fadeIn();
                    correct = true;
                    resumeID = response;
                },
                error: function () {
                    $(this).attr('disabled', false);
                    correct = false;
                    $message.addClass('error');
                    $message.fadeIn(500).delay(2000).fadeOut(500);
                    $messageText.html('Při nahrávání souboru došlo k chybě.');
                }
            })
        ).done(function() {
            if (correct && resumeID != null) {
                $.ajax({
                    url: '/save-job-offer-form',
                    type: 'json',
                    contentType: 'application/json',
                    method: 'post',
                    processData: false,
                    data: JSON.stringify({
                        name: $jobForm.find('input[name="name"]').val(),
                        surname: $jobForm.find('input[name="surname"]').val(),
                        email: $jobForm.find('input[name="email"]').val(),
                        phone: $jobForm.find('input[name="phone"]').val(),
                        text: $jobForm.find('textarea[name="text"]').val(),
                        position: $jobForm.data('position'),
                        resumeID: resumeID,
                        locale: locale
                    }),
                    error: function (response) {
                        $(this).attr('disabled', false);
                        $message.addClass('error');
                        $message.fadeIn(500).delay(2000).fadeOut(500);
                        $messageText.html($.parseJSON(response.responseText).message);
                        $loader.fadeOut(500);
                    },
                    success: function (data, textStatus, response) {
                        ga('send', 'event', 'form', 'sent', 'kariera');
                        $('#job-offer-form-submit').hide();
                        $message.fadeIn(500).delay(2000).fadeOut(500);
                        $message.removeClass('error');
                        $messageText.html($.parseJSON(response.responseText).message);
                        $loader.fadeOut(500);
                    }
                });
            }
        });

    });

});


function detectMobileDevice(){
    if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)){
    }
}

function smoothScroll(elem) {
    $(elem).click(function(event){
        event.preventDefault();
        let menuHeight;
        if ($(window).innerWidth() >= menuBreakpoint) {
            menuHeight = 145;
        } else {
            menuHeight = 74;
        }
        $('html,body').animate({scrollTop:$(this.hash).offset().top - menuHeight}, 500);
    });
}
/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
function detectIE() {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
}
